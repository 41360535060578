import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import { injectIntl, Link, FormattedMessage } from "gatsby-plugin-intl"


const renderIt = () => {
  return <>
    <h4 className="display-medium font-300 text-primary mb-3">Cookie policy</h4>    
    <p>
      Informativa resa ai sensi dell’art.13 del D. L. vo 30 giugno 2003 n. 196 “Codice in materia di protezione dei dati personali”.
    </p>
    <p>
      Il sito INMAGIK SRL fa uso di 2 tipologie di cookie:
      <ul className="mt-2">
        <li>
        Cookie tecnici: INMAGIK SRL non fa uso di cookie per la trasmissione di informazioni di carattere personale, ovvero sistemi per il tracciamento degli utenti.
        I cookie tecnici sono indispensabili per il corretto funzionamento del sito. Il contenuto dei cookie è strettamente limitato alla trasmissione di identificativi di sessione (costituiti da stringhe casuali generati dal server) necessari per consentire l'esplorazione sicura ed efficiente del sito.La durata dei cookie è strettamente limitata alla sessione di lavoro.
        <br></br>
        I c.d. cookie di sessione utilizzati in questo sito evitano il ricorso ad altre tecniche informatiche potenzialmente pregiudizievoli per la riservatezza della navigazione degli utenti e non consentono l'acquisizione di dati personali identificativi dell'utente.
        </li>
        <li>
        Cookie di terze parti: Visitando il sito web INMAGIK SRL si possono ricevere cookie Google Analytics. Tali cookie hanno finalità esclusivamente di tipo statistico nella navigazione del sito INMAGIK, mediante Google Analytics e sono anonimizzati in modo da non rendere riconoscibile l'utente che effettua la navigazione
        Per maggiori informazioni sulle modalità di lavoro del cookie Google Analytics si invita a leggere l'informativa di dettaglio: http://www.google.com/intl/it_ALL/analytics/learn/privacy.html
        </li>
      </ul>
    </p>
      
  </>
}


const renderEn = () => {
  return <>
    <h4 className="display-medium font-300 text-primary mb-3">Cookie policy</h4>    
    <p>
      Informativa resa ai sensi dell’art.13 del D. L. vo 30 giugno 2003 n. 196 “Codice in materia di protezione dei dati personali”.
    </p>
    <p>
      Il sito INMAGIK SRL fa uso di 2 tipologie di cookie:
      <ul className="mt-2">
        <li>
        Cookie tecnici: INMAGIK SRL non fa uso di cookie per la trasmissione di informazioni di carattere personale, ovvero sistemi per il tracciamento degli utenti.
        I cookie tecnici sono indispensabili per il corretto funzionamento del sito. Il contenuto dei cookie è strettamente limitato alla trasmissione di identificativi di sessione (costituiti da stringhe casuali generati dal server) necessari per consentire l'esplorazione sicura ed efficiente del sito.La durata dei cookie è strettamente limitata alla sessione di lavoro.
        <br></br>
        I c.d. cookie di sessione utilizzati in questo sito evitano il ricorso ad altre tecniche informatiche potenzialmente pregiudizievoli per la riservatezza della navigazione degli utenti e non consentono l'acquisizione di dati personali identificativi dell'utente.
        </li>
        <li>
        Cookie di terze parti: Visitando il sito web INMAGIK SRL si possono ricevere cookie Google Analytics. Tali cookie hanno finalità esclusivamente di tipo statistico nella navigazione del sito INMAGIK, mediante Google Analytics e sono anonimizzati in modo da non rendere riconoscibile l'utente che effettua la navigazione
        Per maggiori informazioni sulle modalità di lavoro del cookie Google Analytics si invita a leggere l'informativa di dettaglio: http://www.google.com/intl/it_ALL/analytics/learn/privacy.html
        </li>
      </ul>
    </p>
  </>
    
  
}



const Page = ({data, intl}) => {
  
  return (
  <Layout>
    <SEO title="Privacy policy - INMAGIK" />
    <div className="main-content container p-4 font-100">
      {intl.locale === 'it' ?  renderIt() : renderEn()}
    </div>
  </Layout>
)}

export default injectIntl(Page)
